import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import "../scss/style.scss";
import logo from "../images/logo.svg";

export default function Layout({ children }) {
  return (
    <>
      <Helmet>
        <script
          src="https://consent.cookiefirst.com/banner.js"
          data-cookiefirst-key="21af4575-51cd-412d-a6ed-944fd871069b"
        ></script>
      </Helmet>
      <header className="navbar">
        <div className="container">
          <Link to="/" className="logo">
            <img src={logo} alt="" />
          </Link>
          <input className="menu-btn" type="checkbox" id="menu-btn" />
          <label className="menu-icon" htmlFor="menu-btn">
            <span className="navicon"></span>
          </label>
          <ul className="menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/reserved/">Reserved</Link>
            </li>
            <li>
              <Link to="/ordered/">Ordered</Link>
            </li>
            <li>
              <Link to="/pricing/">Pricing</Link>
            </li>
            <li>
              <Link className="btn-subscribe" to="/register/">
                Register
              </Link>
            </li>
          </ul>
        </div>
      </header>

      <div>{children}</div>

      <footer className="footer">
        <section className="section">
          <div className="container">
            <div className="row reverse stretch">
              <div className="col">
                <h3>Contact us</h3>
                <p>
                  All our services are online. Registration, setting up,
                  renewals et al. However if you wish you contact us the best
                  way is to drop us an email to info@vip-dining.co.uk
                </p>
                <p>Normally we aim to respond to that in 24 hours.</p>
              </div>

              <div className="col">
                <h3>Quick Links</h3>
                <div className="links">
                  <Link to="/">Home</Link> |{" "}
                  <Link to="/reserved/">Reserved</Link> |{" "}
                  <Link to="/ordered/">Ordered</Link> |{" "}
                  <Link to="/pricing/">Pricing</Link> |{" "}
                  <Link to="/privacy/">Privacy Policy</Link> |{" "}
                  <Link to="/cookies/">Cookie Policy</Link>
                </div>
                <div className="copyright">Copyright © 2021. VIP Dine Ltd.</div>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
}
